import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import {
  About,
  Content,
  HeaderTop,
  ProjectGrid,
  LandingPage
} from "./components";
import './App.css';

class App extends Component {

  render() {
    return (
      <Router>
        <HeaderTop />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/project/:projectId" children={<Content />} />
          <Route path="/">
            <ProjectGrid />
          </Route>
        </Switch>
        <LandingPage />
      </Router>
    );
  }
}

export default App;
