import React from "react";

import "../content/content.css";

const About = () => {
    return (
      <div className={"mainContent"} id="content">
        <div className={"imgWrapper"}>
          <h1 className={"contentTitle"}>~ about</h1>
          <img src="https://lindsworld.s3.us-east-1.amazonaws.com/about.jpeg" width={"200px"} alt="profile" />
          <p className={"contentBody"}>
            Lindsay Gilbert is a software engineer based in New York. She has a Master's in Computer Science with a focus in
            Machine Learning from the Georgia Institute of Technology. Her
            work takes its form in blockchain games, conceptual art projects, social experiments, and bad robots.
          </p>
          <p className={"press"}>
                    Email: lindsaygilbert722@gmail.com
                </p>
                <a
                    className={"linkToProject"}
                    target="_blank"
                    rel="noreferrer"
                    href={"https://www.instagram.com/lindsayrgil/"}
                >
                    Instagram: 
                    @lindsayrgil
                </a>
        </div>
      </div>
    );
}

export default About;