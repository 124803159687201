import React from 'react';
import { Link } from 'react-router-dom';
import './project-grid.css';

const ProjectGrid = () => {
    const projects = {
        0: {
            image: "https://lindsworld.s3.amazonaws.com/m-m-m.png"
        },
        1: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/richcaldwellsquamarine_glass121_201_16_06_annotated.png"
        },
        2: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/btn_main.jpeg"
        },
        3: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/button_mini_pilot.jpg"
        },
        4: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/tfiz-stream.png"
        },
        5: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/perfect_person.jpg"
        }
    };

    return (
        <div className="projectGridWrapper">
            <div className="item">
                <Link to="/project/0">
                    <img
                        src={projects[0].image}
                        alt="project"
                        className="projImg"
                    />
                </Link>
                <Link to="/project/3">
                    <img
                        src={projects[3].image}
                        alt="project"
                        className="projImg"
                    />
                </Link>
            </div>
            <div className="item">
                <Link to="/project/1">
                    <img
                        src={projects[1].image}
                        alt="project"
                        className="projImg"
                    />
                </Link>
                <Link to="/project/2">
                    <img
                        src={projects[2].image}
                        alt="project"
                        className="projImg"
                    />
                </Link>
            </div>
            <div className="item">
                <Link to="/project/5">
                    <img
                        src={projects[5].image}
                        alt="project"
                        className="projImg"
                    />
                </Link>
                <Link to="/project/4">
                    <img
                        src={projects[4].image}
                        alt="project"
                        className="projImg"
                    />
                </Link>
            </div>
        </div>
    );
}

export default ProjectGrid;