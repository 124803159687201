import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import './header.css';

export class HeaderTop extends Component {
  
  render() {
    return (
        <>
            <div className={"header headerTop"}>
                <Link to="/projects">
                    <span className={"projectsText headerTopText"}>
                        projects
                    </span>
                </Link>
                <span className={"nameText headerTopText"}>
                    Lindsay Gilbert
                </span>
                <Link to="/about">
                    <span className={"aboutText headerTopText"}>
                        about
                    </span>
                </Link>
            </div>
        </>

    );
  }
}
