import React, { Component } from "react";
import * as THREE from "three";

import "./landing-page.css";

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {mouse_x: 0, mouse_y: 0}
  }

  componentDidMount() {
    var scene = new THREE.Scene();
    var camera = new THREE.OrthographicCamera(
      -1, // left
      1, // right
      1, // top
      -1, // bottom
      -1, // near,
      1 // far
    );
    var plane = new THREE.PlaneBufferGeometry(2, 2);
    let uniforms = {
      u_mouse:  { type: "vec2", value: new THREE.Vector2() },
      u_time: { type: "float", value: 0 },
      u_resolution: { type: "vec3", value: new THREE.Vector3() },
    };
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, 2000);
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.mount.appendChild(renderer.domElement);
    // var geometry = new THREE.BoxGeometry(1, 1, 1);
    var material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: this.fragmentShader()
      // vertexShader: this.vertexShader(),
    });

    scene.add(new THREE.Mesh(plane, material));
    // camera.position.z = 5;

    const resizeDisplay = (renderer) =>  {
      const {clientWidth, clientHeight, height, width} = renderer.domElement;
      const currHeight = window.innerHeight;
      const currWidth = window.innerWidth;
      const needResize = (window.innerWidth !== width) || (window.innerHeight !== height);
      if (needResize) {
        renderer.setSize(currWidth, 2000);
      }
      return needResize;
    }

    var animate = (time) => {
      resizeDisplay(renderer);
      
      time *= 0.001;
      uniforms.u_mouse.value.set(this.state.mouse_x, this.state.mouse_y);
      uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight, 1);
      uniforms.u_time.value = time;
      requestAnimationFrame(animate);
      // cube.rotation.x += 0.01;
      // cube.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    animate();
  }

  _onMouseMove = (e) => {
    this.setState({
      mouse_x: ((window.innerWidth - e.screenX) / window.innerWidth),
      mouse_y: ((window.innerHeight - e.screenY) / window.innerHeight)
    });
  }

  vertexShader() {
    return `
      varying vec3 vUv; 

      void main() {
        vUv = position; 

        vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
        gl_Position = projectionMatrix * modelViewPosition; 
      }
    `;
  }

  fragmentShader() {
    return `
      #include <common>

      uniform vec3 u_resolution;
      uniform vec2 u_mouse;
      uniform float u_time; 

      void mainImage( out vec4 fragColor, in vec2 fragCoord )
      {
        // normalized pixel coords
        vec2 uv = fragCoord/u_resolution.xy;

        // time varying pixel color
        vec3 col = 0.5 + 0.5*cos(u_time+uv.xyx*40.0+vec3(0,2,4));

        //output to screen
        fragColor = vec4(col, 1.0);
      }
      void main() {
        //mainImage(gl_FragColor, gl_FragCoord.xy);
        vec2 st = gl_FragCoord.xy/u_resolution.xy;
        gl_FragColor = vec4(st.x, u_mouse.x, u_mouse.y, 1.0);
      }
  `;
  }

  render() {
    return <div ref={(ref) => (this.mount = ref)} onMouseMove={this._onMouseMove} />;
  }
}

export default LandingPage;
