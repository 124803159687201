import React from 'react';
import {useParams} from "react-router-dom";
import Projects from "./contentInfo";
import './content.css';

const ContentHeader = (props) => {
    return (
        <h1 className={"contentTitle"}>{props.title}</h1>
    );
}

const ContentBody = (props) => {
    return (
        <p className={"contentBody"}>{props.body}</p>
    );
}

const ContentTechUsed = (props) => {
    return (
        <>
            <span>
                <p className={"press"}><b>Tech Used:  </b> {props.techUsed}</p>
            </span>
        </>

    );
}

const ContentPress = (props) => {
    return (
        <>
            <p className={"press"}> <b>Press: </b>
                {props.press.map(pressBlock => (
                    <a
                        className={"pressLink"}
                        target="_blank"
                        rel="noreferrer"
                        href={pressBlock.link}
                    >
                        {pressBlock.title}
                    </a>
                ))}
            </p>
        </>
    );
}

const ContentLink = (props) => {
    return (
        <a
            className={"linkToProject contentProject"}
            target="_blank"
            rel="noreferrer"
            href={props.linkToProject}
        >
            {props.linkToProjectCopy}
        </a>
    );
}

const Content = () => {
    let { projectId } = useParams();
    const projects = {
        0: {
            image: "https://lindsworld.s3.amazonaws.com/m-m-m.png"
        },
        1: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/richcaldwellsquamarine_glass121_201_16_06_annotated.png"
        },
        2: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/btn_main.jpeg"
        },
        3: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/button_mini_pilot.jpg"
        },
        4: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/tfiz-stream.png"
        },
        5: {
            image: "https://lindsworld.s3.us-east-1.amazonaws.com/perfect_person.jpg"
        }
    };

    return (
        <>
            <div className={"mainContent"} id="content">
                <div className={"imgWrapper"}>
                    <div>
                            <img
                                src={projects[parseInt(projectId)].image}
                                alt="project"
                                width={"800px"}
                                className={"image"}
                            />
                            <ContentHeader
                                title={Projects.projects[parseInt(projectId)].title}
                            />
                            <ContentBody
                                body={Projects.projects[parseInt(projectId)].about}
                            />
                            <ContentTechUsed
                                techUsed={Projects.projects[parseInt(projectId)].techUsed}
                            />
                            {Projects.projects[parseInt(projectId)].press.length > 0 &&
                                <ContentPress
                                    press={Projects.projects[parseInt(projectId)].press}
                                />
                            }
                            <ContentLink
                                linkToProjectCopy={Projects.projects[parseInt(projectId)].linkToProjectCopy}
                                linkToProject={Projects.projects[parseInt(projectId)].linkToProject}
                            />
                        </div>
                </div>
            </div>
        </>
    );
}

export default Content;